(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/content-reveal/assets/javascripts/react-content-reveal.js') >= 0) return;  svs.modules.push('/components/components/content-reveal/assets/javascripts/react-content-reveal.js');
"use strict";

function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

class ContentReveal extends React.Component {
  constructor() {
    super(...arguments);
    _defineProperty(this, "state", {
      animateShow: this.props.show,
      animateShowFinished: false,
      render: this.props.show
    });
    _defineProperty(this, "elemRef", React.createRef());
    _defineProperty(this, "handleCloseAnimationFinished", event => {
      if (event.propertyName !== 'max-height') {
        return;
      }
      if (this.elemRef.current) {
        this.elemRef.current.removeEventListener('transitionend', this.handleCloseAnimationFinished);
      }
      if (this.state.animateShow) {
        return;
      }
      clearTimeout(this.animationTimeout);
      this.setState({
        animateShow: false,
        animateShowFinished: false,
        render: false
      });
    });
    _defineProperty(this, "handleOpenAnimationFinished", () => {
      if (this.elemRef.current) {
        this.elemRef.current.removeEventListener('transitionend', this.handleOpenAnimationFinished);
      }
      this.setState({
        animateShowFinished: true
      });
    });
  }
  componentDidUpdate(_ref, prevState) {
    let {
      show
    } = _ref;
    if (show !== this.props.show) {
      clearTimeout(this.animationTimeout);
    }
    if (!show && this.props.show) {
      this.setState({
        render: true
      });
      this.animationTimeout = setTimeout(() => {
        this.setState({
          animateShow: true
        });
      });
    }
    if (this.state.render && !prevState.render) {
      this.elemRef.current.addEventListener('transitionend', this.handleOpenAnimationFinished);
    }
    if (show && !this.props.show) {
      this.setState({
        animateShow: false
      });
      if (this.elemRef.current) {
        this.elemRef.current.addEventListener('transitionend', this.handleCloseAnimationFinished);
      }
    }
  }
  render() {
    if (!this.state.render) {
      return null;
    }
    const className = [this.props.className, 'react-content-reveal'].concat(this.state.animateShow && 'react-content-reveal-show').concat(this.state.animateShowFinished && 'react-content-reveal-show-animation-finished').filter(c => Boolean(c)).join(' ');
    return React.createElement("div", {
      className: className,
      ref: this.elemRef
    }, this.props.children);
  }
}
ContentReveal.defaultProps = {
  className: ''
};
svs.components = svs.components || {};
svs.components.ContentRevealReact = ContentReveal;

 })(window);